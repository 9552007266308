import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Line",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/line.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Line`}</h1>
    <p>{`Reply to your Line messages from Crisp`}</p>
    <hr></hr>
    <p>{`Line is a very popular messaging app in Asia with more than 700 million users. This integration let you Connect Crisp to a Line account so your Line users can reach you using their favorite app. You can reply to them using your Crisp Inbox. It's compatible with all the Crisp ecosystem, like the Bot integration, Slack or Zendesk.`}</p>
    <p>{`Some benefits of using Crisp with Line:`}</p>
    <ul>
      <li parentName="ul">{`Avoid distraction by keeping all your chats in Crisp`}</li>
      <li parentName="ul">{`Be more productive with the Crisp Inbox features (Knowledge Base, Shortcuts, Assignments)`}</li>
      <li parentName="ul">{`Integrate it with your team tools`}</li>
      <li parentName="ul">{`Re-target your Line users`}</li>
      <li parentName="ul">{`Preserves most messaging features like buttons, file-sharing, gifs and emojis`}</li>
      <li parentName="ul">{`Build a Line bot in few clicks with the Crisp bot feature`}</li>
      <li parentName="ul">{`Messages you receive are stored in the Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Use the Crisp search engine to search previous conversations`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      